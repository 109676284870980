import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { User, User_Post } from "../models/user.model";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { UnsubscribeOnDestroyAdapter } from "../../shared/UnsubscribeOnDestroyAdapter";
import { environment } from "src/environments/environment";

@Injectable()
export class UsuariosService extends UnsubscribeOnDestroyAdapter {
  private readonly API_URL = "assets/data/contacts.json";
  isTblLoading = true;
  dataChange: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private httpClient: HttpClient) {
    super();
  }
  get data(): User[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }
  /** CRUD METHODS */
  getAllUsers(): void {
    this.subs.sink = this.httpClient.get<User[]>(`${environment.apiUrl}/User/GetAllUsers`).subscribe(
      (data) => {
        this.isTblLoading = false;

        this.dataChange.next(data);
      },
      (error: HttpErrorResponse) => {
        this.isTblLoading = false;
        console.log(error.name + " " + error.message);
      }
    );
  }
  addUser(users: User_Post): void {
    //this.dialogData = users;  
    console.log(users);
    this.httpClient.post(`${environment.apiUrl}/User/Add`, users).subscribe(data => {
      this.dialogData = users;
      },
      (err: HttpErrorResponse) => {
     // error code here
    });
  }
  updateUser(users): void {
    //this.dialogData = users;
    this.httpClient.post(`${environment.apiUrl}/User/Update`,users).subscribe(data => {
      this.dialogData = users;
    },
    (err: HttpErrorResponse) => {
      // error code here
    }
    );
  }
  deleteUsers(id: number): void {
    console.log(id);

    /*  this.httpClient.delete(this.API_URL + id).subscribe(data => {
      console.log(id);
      },
      (err: HttpErrorResponse) => {
         // error code here
      }
    );*/
  }
}

@Injectable()
export class OperacionesService extends UnsubscribeOnDestroyAdapter {
  private readonly API_URL = "assets/data/operaciones.json";
  isTblLoading = true;
  dataChange: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private httpClient: HttpClient) {
    super();
  }
  get data(): User[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }
  //CRUD METHODS 
  getAllUsers(): void {
    console.log("users");
    this.subs.sink = this.httpClient.get<User[]>(`${environment.apiUrl}/GetAllUsers`).subscribe(
      (data) => {
        this.isTblLoading = false;
        this.dataChange.next(data);
      },
      (error: HttpErrorResponse) => {
        this.isTblLoading = false;
        console.log(error.name + " " + error.message);
      }
    );
  }
  addContacts(user: User): void {
    this.dialogData = user;

  }
  updateContacts(user: User): void {
    this.dialogData = user;

  }
  deleteContacts(id: number): void {
    console.log(id);

  }
}

